import React, { useEffect, useState } from 'react';
import { ReactComponent as LogoBlack } from '../Full_Logo_Black_CMYK.svg';
import { ReactComponent as LogoWhite } from '../Full_Logo_White_CMYK.svg';
import { ReactComponent as LogoGreen } from '../Full_Logo_Green_CMYK.svg';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Disconnect from './Disconnect';
import { Button, Box, Typography } from '@mui/material';
import './LandingPage.css';

const LandingPage = () => {
  const [privacy, setPrivacy] = useState(null);
  const [terms, setTerms] = useState(null);
  const [disconnect, setDisconnect] = useState(null);
  const [showModal, setShowModal] = useState(false); // New: State for modal visibility
  const [agreed, setAgreed] = useState(false); // New: State for user agreement

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lp = urlParams.get('privacy-policy');
    setPrivacy(lp);
  }, [privacy]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lp = urlParams.get('terms-of-service');
    setTerms(lp);
  }, [terms]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lp = urlParams.get('disconnect');
    setDisconnect(lp);
  }, [disconnect]);

  const handleNavigate = () => {
    if (!agreed) {
      setShowModal(true); // Show modal if user hasn't agreed
    } else {
      window.location.href = `/?landingP=true`; // Proceed to explore festivals
    }
  };

  const handleDisconnect = () => {
    window.location.href = `/?disconnect=true`;
  };

  const handleAgree = () => {
    setAgreed(true); // Set agreement state
    setShowModal(false); // Hide modal
    window.location.href = `/?landingP=true`; // Proceed to explore festivals
  };

  return (
    <div>
      {privacy ? (
        <PrivacyPolicy />
      ) : terms ? (
        <TermsOfService />
      ) : disconnect ? (
        <Disconnect />
      ) : (
        <div className="landing-page">
          <div className="landing-page-overlay"></div>
          <button className="landing-page-disconnect-button" onClick={handleDisconnect}>
            Disconnect
          </button>
          <div className="landing-page-content">
            <h1 className="landing-page-title">
              Create Festival Playlists from Your Favorite Music
            </h1>
            <p className="landing-page-subtitle">
              Discover songs by your favorite artists performing at upcoming festivals.
            </p>
            <button className="landing-page-button" onClick={handleNavigate}>
              Explore Festivals
            </button>
          </div>
          <footer className="landing-page-footer">
            <p>
              <a href="/?privacy-policy=true" className="footer-link">
                Privacy Policy
              </a>{' '}
              |{' '}
              <a href="/?terms-of-service=true" className="footer-link">
                Terms of Service
              </a>
            </p>
            <p>
              Your Festival Playlist is an independent app and is not endorsed by Spotify or any artist, record label, or sound recording.
            </p>
          </footer>
        </div>
      )}

{/* Updated Consent Modal */}
{showModal && (
  <div className="modal">
    <div className="modal-content">
      <h2>Welcome to Your Festival Playlist!</h2>
      <p>
        By using this app, you agree to our{' '}
        <a href="/?terms-of-service=true">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="/?privacy-policy=true">
          Privacy Policy
        </a>.
      </p>
      <label>
        <input
          type="checkbox"
          checked={agreed}
          onChange={(e) => setAgreed(e.target.checked)}
        />
        I agree to the Terms of Service and Privacy Policy.
      </label>
      <button onClick={handleAgree} disabled={!agreed}>
        Continue
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default LandingPage;