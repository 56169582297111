import React from 'react';
import Button from '@mui/material/Button';
import { ReactComponent as LogoWhite } from '../Full_Logo_White_CMYK.svg';
import './LandingPage.css';


const handleBackToLanding = () => {
    window.location.href = "/"; // Redirects to the landing page
  };

const Disconnect = ({ createPlaylist }) => (

  <div className="landing-page">    
      <div className="landing-page-overlay"></div>

      <div className="landing-page-content">
  <h1 className="landing-page-title">
    Steps to disconnect your Spotify account from Your Festival Playlist
  </h1>
  <ol className="landing-page-steps">
    <li>Go to <a href="https://www.spotify.com/account/apps/" target="_blank" rel="noopener noreferrer">Spotify Account Apps </a> (https://www.spotify.com/account/apps/)</li>
    <li>Log in to your Spotify account if prompted.</li>
    <li>Locate "Your Festival Playlist" in the list of connected apps.</li>
    <li>Click "Remove Access" to disconnect.</li>
  </ol>
</div>
      <button className="landing-page-back-button" onClick={handleBackToLanding}>
        Go back
        </button>
      </div>
      

);

export default Disconnect;